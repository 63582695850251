<template>
  <div>
    <div class="app-header">
      <img :alt="$config.client.name" :src="logoPath"/>
    </div>
    <div class="center">
      <b-container class="msg">
        <p>
          {{ $t('signupSuccess.message')[0] }}<br>
           {{ $t('signupSuccess.message')[1] }}
        </p>
        <!-- <b-button type="button" variant="outline-primary" @click.prevent="$router.push({ name: 'SignIn' })">
          Ouvrir la page de connexion
        </b-button> -->
      </b-container>
    </div>
    <small class="footer">
      <p>{{ $t('footer') }} <a href="https://www.neogeo.fr/" target="_blank" rel="noopener">Neogeo-Technologies</a></p>
    </small>
  </div>
</template>

<script>
export default {
  
  name: 'SignUpSuccess',

  data: () => {
    return { };
  },

  computed: {
    logoPath() {
      return require(process.env.VUE_APP_LOGO);
    }
  }
};
</script>

<style lang="less" scoped>

.center {
  position: initial;
}

.center .msg.container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.center .msg.container,
.center .msg.container button {
  font-size: large;
}

.footer {
  position: absolute;
  bottom: 0;
  font-size: small;
}

.footer a {
  text-decoration: none;
}
</style>
